export const classList = [
    { semester: "Fall 2020", courseCode: "CO-150", courseName: "College Composition (GT-CO2)", credits: 3, technologiesLearned: "", description: "" },
    { semester: "Fall 2020", courseCode: "CS-150", courseName: "Culture and Coding: Python (GT-AH3)", credits: 3, technologiesLearned: "Python", description: "Introduction to coding concepts using Python, focusing on cultural impacts and basic programming skills." },
    { semester: "Fall 2020", courseCode: "CS-192", courseName: "Introduction to Computing (GT-AH3)", credits: 1, technologiesLearned: "Basic Computing Concepts", description: "Foundational course in computing, covering essential concepts and introductory programming." },
    { semester: "Fall 2020", courseCode: "PSY-100", courseName: "General Psychology (GT-SS3)", credits: 3, technologiesLearned: "", description: "" },
    { semester: "Fall 2020", courseCode: "MAT-124/125", courseName: "Logarithmic and Exponential Functions/Trigonometric Functions", credits: 2, technologiesLearned: "", description: "" },
    { semester: "Spring 2021", courseCode: "CS-163", courseName: "Java Programming No Prior Programming Experience", credits: 4, technologiesLearned: "Java", description: "Introduction to Java programming, focusing on foundational programming concepts for beginners." },
    { semester: "Spring 2021", courseCode: "CHEM 107", courseName: "Fundamentals of Chemistry (GT-SC2)", credits: 4, technologiesLearned: "", description: "" },
    { semester: "Spring 2021", courseCode: "PHIL 170", courseName: "Ethical and Political Interpretations (GT-AH3)", credits: 3, technologiesLearned: "", description: "" },
    { semester: "Spring 2021", courseCode: "PHIL 201", courseName: "Ethical Computing Systems (GT-AH3)", credits: 3, technologiesLearned: "", description: "" },
    { semester: "Spring 2021", courseCode: "MATH 126", courseName: "Mathematical Analysis I", credits: 1, technologiesLearned: "", description: "" },
    { semester: "Summer 2021", courseCode: "MATH 160", courseName: "Calculus for Physical Scientists I (GT-MA1)", credits: 5, technologiesLearned: "", description: "" },
    { semester: "Fall 2021", courseCode: "CS 165", courseName: "CS2--Data Structures", credits: 4, technologiesLearned: "Java, Data Structures", description: "Advanced study of data structures in Java, including arrays, linked lists, and trees." },
    { semester: "Fall 2021", courseCode: "CS-270", courseName: "Computer Organization", credits: 4, technologiesLearned: "Assembly Language, Computer Architecture", description: "Exploration of the fundamental concepts of computer organization and assembly language programming." },
    { semester: "Fall 2021", courseCode: "BZ 120", courseName: "Principles of Plant Biology (GT-SC1)", credits: 4, technologiesLearned: "", description: "" },
    { semester: "Fall 2021", courseCode: "HIST 100", courseName: "Western Civilization, Pre-Modern (GT-HI1)", credits: 3, technologiesLearned: "Critical Thinking, and writing", description: "Learning western history" },
    { semester: "Spring 2022", courseCode: "CS-220", courseName: "Discrete Structures and their Applications", credits: 4, technologiesLearned: "Discrete Mathematics, Python, Java", description: "Study of discrete structures and algorithms essential for computer science, including logic, set theory, and combinatorics." },
    { semester: "Spring 2022", courseCode: "JTC 372", courseName: "Web Design and Development", credits: 3, technologiesLearned: "HTML, CSS, Javascript", description: "Learning the basics of webdesign" },
    { semester: "Summer 2022", courseCode: "CS 370", courseName: "Operating Systems", credits: 3, technologiesLearned: "Operating System Concepts, CPP, Java, Python, C", description: "CS 370 delves into operating systems, covering fundamental concepts such as processes, threads, scheduling algorithms, and memory management. It emphasizes interprocess communication, synchronization, and handling of deadlocks in resource management. The course also explores file systems, storage architecture, symmetric multiprocessing, and virtualization." },
    { semester: "Fall 2022", courseCode: "CS 253", courseName: "Software Development with C++", credits: 4, technologiesLearned: "C++, Valgrind, GDB, Nano, Linux, CLion", description: "Continued exploration of C++ with emphasis on advanced object-oriented concepts and software development techniques." },
    { semester: "Fall 2022", courseCode: "PSY 252", courseName: "Mind, Brain, and Behavior", credits: 3, technologiesLearned: "Critical Thinking", description: "Learning about psychological, biological, and evolutionary explanations of perception, cognition, and behavior" },
    { semester: "Fall 2022", courseCode: "JTC 300", courseName: "Professional and Technical Communication", credits: 3, technologiesLearned: "Professional Writing", description: "Advance writing course" },
    { semester: "Fall 2022", courseCode: "DSCI 369", courseName: "Linear Algebra for Data Science", credits: 4, technologiesLearned: "Linear Algebra, Matlab", description: "Application of linear algebra concepts in data science, including matrix operations and vector spaces." },
    { semester: "Spring 2023", courseCode: "CS 314", courseName: "Software Engineering", credits: 3, technologiesLearned: 'Software Development Lifecycle, Agile Methodologies, Linux, Java, JavaScript, React, Reactstrap, React Leaflet, SQL, MariaDB, Java Spark, Java Concurrency,\n SLF4J, uLog, JSON, CSV, SVG, and KML.', description: "Study of software engineering principles, including software development lifecycle, testing, and agile methodologies." },
    { semester: "Spring 2023", courseCode: "STAT 301", courseName: "Introduction to Applied Statistical Methods", credits: 3, technologiesLearned: "Matlab, Linear Regression Modeling", description: ":Learning basic statistics" },
    { semester: "Spring 2023", courseCode: "CS 356", courseName: "Systems Security", credits: 3, technologiesLearned: "Network Security, Cryptography, Penn Testing, CPP, C, Python", description: "Introduction to systems security concepts, including network security, cryptography, and vulnerability assessment." },
    { semester: "Spring 2023", courseCode: "CS 312", courseName: "Modern Web Development", credits: 3, technologiesLearned: "Web Technologies, Frameworks, Fuel PHP, Java, MariaDB, SQL, HTML, CSS, Javascript", description: "Focus on modern web development techniques and frameworks, covering both front-end and back-end development." },
    { semester: "Fall 2023", courseCode: "CS 456", courseName: "Modern CyberSecurity", credits: 4, technologiesLearned: "Encryption(ECC, DH, RSA, Etc), Network security, web privacy, CPP, Python", description: "Advanced study of cybersecurity techniques and practices, including threat analysis and defense strategies." },
    { semester: "Fall 2023", courseCode: "CS 457", courseName: "Computer Networks and the Internet", credits: 4, technologiesLearned: "Networking Protocols, Internet Architecture, Socket Programming in C, Python", description: "Comprehensive study of computer networks, focusing on internet protocols, network architecture, and data communication." },
    { semester: "Fall 2023", courseCode: "IDEA 210", courseName: "Design Thinking", credits: 3, technologiesLearned: "Prototyping, Designing, Planning, Design Sprints", description: "Learning the Design thinking Framework. " },
    { semester: "Fall 2023", courseCode: "CS 414", courseName: "Object Oriented Software Engineering", credits: 4, technologiesLearned: "Object-Oriented Programming, Software Design Patterns, Java, ReactJS, REST APIs, Cohesion and Coupling", description: "In-depth exploration of object-oriented software engineering principles, including design patterns and software architecture." },
    { semester: "Spring 2024", courseCode: "CS 430", courseName: "Database Systems", credits: 4, technologiesLearned: "SQL, Database Management", description: "In-depth exploration of database systems, including SQL, data modeling, and database design." },
    { semester: "Spring 2024", courseCode: "CS 455", courseName: "Intro to Distributed Systems", credits: 4, technologiesLearned: "Distributed Computing, System Design, Hadoop, MapReduce, Networking, C++", description: "Introduction to distributed systems, covering concepts in distributed computing and system design." },
    { semester: "Spring 2024", courseCode: "CS 320", courseName: "Algorithms--Theory and Practice", credits: 3, technologiesLearned: "Algorithm Design, Complexity Analysis", description: "Advanced study of algorithms with a focus on design strategies, efficiency, and computational complexity." }
];