import proj1 from "../static/images/mauricioPort.jpg";
import proj2 from "../static/images/UI.jpg";
import proj3 from "../static/images/pro3.png";
import proj4 from "../static/images/block.png";
export const ProjectList = [
    {
        name: "Personal Portfolio Website",
        image: proj1,
        id: 1,
        description: "A personal portfolio website to showcase my projects and skills.",
        link: "/",
        tools: "React, Material-UI",
        skills: "React, Material-UI, HTML, CSS, JavaScript, Git, GitHub, CloudFlare",
        github: "https://github.com/mauriciog88u1/mauriciog-portfolio"

    },
    {
        name: "Full Stack Chess Application",
        image: proj2,
        id: 2,
        description: "A full stack chess application that allows users to play chess against each other. Utilized REST API to communicate between the front end and back end. Implemented a database to store user information and game history. Implemented a chess engine to validate moves and determine checkmate.",
        link: "/chess",
        tools: "Java Spark, React",
        skills: "IntelliJ IDEA, Linux, Docker, Git, GitHub, Maven, NPM, Webpack, JUnit, Jest, Postman, Zenhub, and Code Climate. The technology tools used include: Linux, Java, JavaScript, React, Reactstrap, React Leaflet, SQL, MariaDB, Java Spark, Concurrency, SLF4J, uLog, JSON, CSV, SVG, and KML",
        github:""
    },
    {
        name: "Web Based Map Tool",
        image: proj3,
        id: 3,
        description: "A comprehensive full-stack web mapping application that empowers users to create, upload, and manage maps in various formats, including JSON. Users can mark multiple points of interest (POIs) on their maps and have the option to apply optimization algorithms such as Nearest Neighbor, 2-opt, and 3-opt to find the most efficient path between these POIs. Additionally, users can search a global airport database and seamlessly add airports to their trips. The application supports exporting maps in multiple formats, including JSON, CSV, and KML, providing flexibility for sharing and collaboration.",
        link: "https://www.youtube.com/watch?v=bTmG8gAi8o8",
        tools: "Java Spark, React, MariaDB, Node.js, Docker, and GitHub Actions ",
        skills: "IntelliJ IDEA, Linux, Docker, Git, GitHub, Maven, NPM, Webpack, JUnit, Jest, Postman, Zenhub, and Code Climate. The technology tools used include: Linux, Java, JavaScript, React, Reactstrap, React Leaflet, SQL, MariaDB, Java Spark, Concurrency, SLF4J, uLog, JSON, CSV, SVG, and KML"
    },
    {
      name:"Block and Stream cipher",
        image: proj4,
        id: 4,
        description: "A implmentation of a block and stream cipher using CPP. On CLI you can encrypt and decrypt files using the block cipher and stream cipher. It takes  in a  input file and key file and you specift the encryption type in order to get the encrypted file.",
        link: "",
        tools: "CPP",
        skills: "CPP, Encryption, Decryption, CLI, and Git"

    }

];
