import React from 'react';
import MyPhoto from '../../src/static/images/mauricio-githubphoto.jpg';
import MyResume from '../../src/static/resume/Mauricio_Gonzalez\'s_2024_Resume.pdf'
import 'bootstrap/dist/css/bootstrap.min.css';

function AboutMe() {
    return (
        <div className="container mt-5 text-white">
            <div className="card bg-dark mb-3">
                <div className="row no-gutters">
                    <div className="col-md-4">
                        <img src={MyPhoto} alt="Mauricio Gonzalez" className="img-fluid rounded-start"/>
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                            <h1 className="card-title">About Me</h1>
                            <p className="card-text">
                                Hello, I'm Mauricio Gonzalez, a first-generation Hispanic Computer Science Student. My
                                journey began at Groundwork Denver at 14, where I was deeply involved in environmental
                                projects. I've worked in tech at Target, served as a Python teaching assistant, and now
                                work as an IT lab tech. My internship at Cisco Meraki as a Network Engineer was a
                                significant step in my career. I am also a mentor for first-generation computer science
                                students, becoming the guide I once needed.
                            </p>
                            <a href={MyResume} download="Mauricio_Gonzalez_Resume.pdf" className="btn btn-primary">Download
                                Resume</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card bg-dark mb-3">
                <div className="card-body">
                    <h2 className="card-title">My Interests</h2>
                    <p className="card-text">
                        Outside my academic and professional life, I actively pursue a balance of mental and physical
                        challenges. My
                        dedication to chess, a skill I developed during my involvement with Groundwork Denver, sharpens
                        my strategic thinking. My work with non-profits like Groundwork Denver has also fostered a
                        strong sense of community engagement and fulfillment from contributing to meaningful causes.
                        Alongside these pursuits, I train in Jiu-Jitsu and engage in regular gym workouts. Recently, I
                        achieved a personal milestone by bench pressing 225 pounds. This accomplishment, combined with
                        my background in wrestling, underscores my commitment to discipline and resilience. Living in
                        Colorado, I am also drawn to a variety of outdoor activities, further contributing to my
                        holistic approach to personal and professional growth.
                    </p>
                </div>
            </div>
            <div className="card bg-dark mb-3">
                <div className="card-body">
                    <h2 className="card-title">My Core Values</h2>
                    <p className="card-text">
                        My values are deeply rooted in fostering diversity and inclusion, creating spaces where open
                        dialogue and inquiry are encouraged. I am committed to environmental and social justice,
                        striving to make impactful changes in these areas. Mentorship is another key value for me,
                        reflecting my dedication to contributing positively to the community. Additionally, maintaining
                        a healthy work-life balance is important, as it enables me to continually evolve and be the most
                        effective version of myself.
                    </p>
                </div>
            </div>
            <div className="card bg-dark mb-3">
                <div className="card-body">
                    <h2 className="card-title">Summary</h2>
                    <p className="card-text">
                        I am deeply passionate about solving problems through technology. My belief that coding empowers
                        one to craft virtually any solution fuels my love for this field. This enthusiasm for
                        technological innovation drives me to continuously explore and create impactful solutions in the
                        tech world.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AboutMe;
