import React from "react";
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import WorkIcon from "@material-ui/icons/Work";
import SchoolIcon from "@material-ui/icons/School";
import "../styles/Experince.css";
import {Link} from "react-router-dom";

function Experience() {
    return (
        <div className="experience">
            <VerticalTimeline>
                {/* Work Experience 2 (Most Current) */}
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="Jan 2023 - Current"
                    iconClassName="work-icon"
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">
                        IT Lab Technician, Colorado State University
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        Fort Collins, Colorado
                    </h4>
                    <p>
                        Maintained network infrastructure, automated tasks with shell
                        scripting, and contributed to cybersecurity initiatives.
                    </p>
                    <p>
                        Responded to and resolved various IT-related issues and was able to contrubute the the development of
                        internal tools.
                    </p>


                </VerticalTimelineElement>

                {/* Work Experience 4 */}
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="Fall 2022 - Current"
                    iconClassName="work-icon"
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">
                        Latinx Computer Science Mentor, Colorado State University
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        Fort Collins, Colorado
                    </h4>
                    <p>
                        Mentor to 15-20 Hispanic first-generation students in Computer Science.
                    </p>
                    <p>
                        Successfully increased the retention rate of Hispanic students in Computer Science by 20%.
                    </p>
                    <p>
                        Planned and organized various weekly lunches, study sessions, and social events to foster a sense of community among students.
                    </p>
                </VerticalTimelineElement>


                {/* Work Experience 1 */}
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="May 2023 - Aug 2023"
                    iconClassName="work-icon"
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">
                        Network Engineer Intern, Cisco Meraki
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        Chicago, Illinois
                    </h4>
                    <p>
                        Developed networking skills, aided in product development and enhancement, automated solutions, and strengthened network security.
                    </p>
                    <p>
                        Worked on an internal troubleshooting tool to increase network troubleshooting efficiency.
                    </p>
                    <p>
                        Worked extensively with Cisco Meraki products and adeptly troubleshooted various networking-related issues.
                    </p>
                </VerticalTimelineElement>


                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="Fall 2022"
                    iconClassName="work-icon"
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">
                        Python Teaching Assistant, Colorado State University
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        Fort Collins, Colorado
                    </h4>
                    <p>
                        Assisted in teaching "Intro to Python CS 150B" course, managed labs, graded
                        various coding projects, and provided guidance to students in learning how to code.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="May 2019 - Aug 2022"
                    iconClassName="work-icon"
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">
                        Youth Supervisor, Groundwork Denver
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">Denver, Colorado</h4>
                    <p>
                        Managed multiple grants, conducted community outreach, and collaborated with partners such as US Fish and Wildlife, Denver Parks and Recreation Park Rangers, and Aurora Parks and Recreation.
                    </p>
                    <p>
                        Automated processes, led community projects, fostered team growth, and managed a team of 6 youth.
                    </p>
                </VerticalTimelineElement>


                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="May 2019 - Sep 2022"
                    iconClassName="work-icon"
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">
                        Tech Associate, Target
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        Aurora, Colorado, United States
                    </h4>
                    <p>
                        Provided expert assistance to customers with technology-related inquiries and issues, including mobile service troubleshooting. Demonstrated a strong commitment to accessibility by simplifying technical concepts for a diverse clientele.</p><p> Leveraged extensive experience and  customer service skills to ensure customer satisfaction. Offered valuable product consultations and adeptly resolved various technical challenges.
                    </p>
                </VerticalTimelineElement>


                {/* Education */}
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="Expected May 2024"
                    iconClassName="education-icon"
                    icon={<Link to="/education" className="education-icon"> <SchoolIcon /> </Link>}
                >
                    <h3 className="vertical-timeline-element-title">
                        Bachelor of Science in Computer Science
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle" style={{ fontSize: '14px' }}>
                        Concentration in Networks and Security
                    </h4>
                    <p>
                        Colorado State University, Fort Collins, Colorado
                    </p>
                </VerticalTimelineElement>

            </VerticalTimeline>
        </div>
    );
}

export default Experience;
